import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import ReactMarkdown from 'react-markdown';
import { Container, Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useParams, Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const BlogPost = () => {
  const [markdownContent, setMarkdownContent] = useState('');
  const { title } = useParams();

  useEffect(() => {
    const fetchMarkdownContent = async () => {
      try {
        const response = await fetch(`/blogs/econ/${title}/${title}.md`);
        const content = await response.text();
        setMarkdownContent(content);
      } catch (error) {
        console.error('Error fetching Markdown content:', error);
      }
    };

    fetchMarkdownContent();
  }, [title]); // Include title as a dependency for useEffect

  return (
    <React.Fragment>
      <AppBar color="default" position="fixed" autoHide>
        <Toolbar>
          <Typography variant="h5" component="div">
            Roman Academy
          </Typography>
        </Toolbar>
      </AppBar>

      <Toolbar />

      <Container style={{ paddingTop: '20px' }}>
        <Grid container spacing={3} style={{ marginTop: '20px' }}>
          <Grid item md={8} p={2}>
            <div className="markdown-content">
              <ReactMarkdown>{markdownContent}</ReactMarkdown>
            </div>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid sx={{ backgroudcolor: 'black' }} item md={3}>
            <Stack
              sx={{
                border: '1px solid blue',
                borderRadius: '8px',
                padding: 2,
              }}
              spacing={2}
            >
              <h2>Зміст:</h2>
              <Link to="/post/week1">
                <Button variant="outlined">Тиждень 1</Button>
              </Link>
              <Link to="/post/week2">
                <Button variant="outlined">Тиждень 2</Button>
              </Link>
              <Link to="/post/week3">
                <Button variant="outlined">Тиждень 3</Button>
              </Link>
              <Link to="/post/week4">
                <Button variant="outlined">Тиждень 4</Button>
              </Link>
            </Stack>
            <h2>Інформація:</h2>
            <p>Цей курс є перекладом. Також є доповнення, які допомогли мені особисто.</p>
            <p>
              Я не перекладав деякі поняття, тому що так чи інакше вони будуть зустрічатися в інтеренеті і важливо розуміти, що це означає.
            </p>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default BlogPost;
