import React, { useState, useEffect } from 'react';
import BlogPost from './BlogPost';
import Home from './Home';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/post/:title" element={<BlogPost />} />
    </Routes>
  </Router>
  );
}

export default App;
