// LanguageSelector.js
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const LanguageSelector = ({ languages, selectedLanguage, onChange }) => {
  return (
    <FormControl>
      <Select
        value={selectedLanguage}
        onChange={onChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Select language' }}
      >
        <MenuItem value="" disabled>
          
        </MenuItem>
        {languages.map((language) => (
          <MenuItem key={language.code} value={language.code}>
            {language.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
