import * as React from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';



function CourseCard({ title, info,link, image }) {
    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardMedia
                sx={{ height: 200 }}
                image= {image}

            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                {title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {info}
                </Typography>
            </CardContent>
            <CardActions>

                <Link to= {link}><Button variant="outlined" >Переглянути</Button></Link>

            </CardActions>
        </Card>





    );
}

export default CourseCard;
