
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import CourseCard from './CourseCard';
import { Grid } from '@mui/material';
import LanguageSelector from './LanguageSelector';
import { useTranslation, withTranslation, Trans } from 'react-i18next';


export default function ElevateAppBar(props) {
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const languages = [
    { code: 'en', name: 'English' },
    { code: 'ua', name: 'Українська' },
    // Add more languages as needed
  ];

  
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
    
  };

  return (
    <React.Fragment>
        <AppBar color="default" autoHide >
          <Toolbar>
            <Typography variant="h5" component="div">
              Roman Academy
            </Typography>
            <LanguageSelector
        languages={languages}
        selectedLanguage={selectedLanguage}
        onChange={changeLanguage}
      />

          </Toolbar>
        </AppBar>
      
      <Toolbar />
      <Container >
        <Grid>
          <h1>{t('welcome')}</h1>
          <h1>Курси:</h1>
        </Grid>
        <Grid container sx={{ m: 2 }}>
          <Grid item md={4}>
          <CourseCard title={'Фінанси та банкінг'} info={'Це курс про фінанси та банкінг'} link={'/post/week1'} image= {"econ.png"} />
          </Grid>
        <Grid item md={4}>
        <CourseCard title={'Макроєкономіка'} info={'Курс в розробці'} link={'/'} image= {"physics.jpg"} />
        </Grid>
        
        </Grid>
      
      </Container>
    </React.Fragment>
  );
}
